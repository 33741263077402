<template>
  <div class="body">
    <TransferListFilter v-model="filterForm" :ids="selectArr" :page="pageData.current_page"
      :uploadFilter="ok" />
    <el-table :data="tbody" class="thead-light" stripe style="width: 100%;margin-top: 20px"
      @sort-change="sortChange" @selection-change="handleSelectionChange" v-loading="loading">
      <!-- 勾选 -->
      <el-table-column class="select-check" fixed type="selection" width="55"></el-table-column>
      <!-- 操作 -->
      <el-table-column label="操作" width="70" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="CheckRow(scope.row)">
            查看
          </el-button>
        </template>
      </el-table-column>
      <el-table-column v-for="(item,index) in thead" :key="index" :label="item.label" :prop="item.prop"
        :min-width="item.minWidth" show-overflow-tooltip :sortable="item.sortable">
        <template slot-scope="scope">
          <span v-if="item.prop === 'images_count'">{{scope.row[item.prop] + ' 张图' | placeholder }}</span>
          <span v-else>{{scope.row[item.prop] | placeholder }}</span>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :page="pageData.current_page" :change="changPage" />
    <TransferDetailDialog v-model="openDetailDialog" :id="current_id" />
  </div>
</template>

<script>
import { myCredential } from "../../api/member/member-detail";
import TransferListFilter from "../../components/Member/TransferListFilter";
import TransferDetailDialog from "../../components/Member/TransferDetailDialog.vue";
import Pagination from "@/base/components/Default/Pagination";
export default {
  props: {
    data: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      thead: [
        { label: "ID", prop: "id", minWidth: 70 },
        { label: "提交人", prop: "user_nickname", minWidth: 110 },
        { label: "提交职务", prop: "position_name", minWidth: 110 },
        { label: "说明", prop: "remark", minWidth: 150 },
        {
          label: "图片",
          prop: "images_count",
          minWidth: 100,
        },
        {
          label: "提交时间",
          prop: "create_time",
          minWidth: 160,
          sortable: true,
        },
      ],
      filterForm: {
        uid: this.data?this.$deCode(this.data).id:this.$route.params.user_id,
        keyword: "",
        start_time: -1,
        end_time: -1,
        page_size: 15,
      },
      tbody: [],
      selectArr: [],
      pageData: {},
      current_id: 0,
      current_image: "",
      openDetailDialog: false,
    };
  },
  methods: {
    // 获取我的企业列表
    getMyCredential(pageData) {
      this.loading = true;
      myCredential(pageData)
        .then((res) => {
          const { data } = res;
          this.tbody = data.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 勾选操作
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = [];
      } else {
        this.selectArr = [];
        // 筛选id
        selection.forEach((item) => {
          this.selectArr.push(item.id);
        });
      }
    },
    // 查看
    CheckRow(row) {
      this.current_id = row.id;
      this.openDetailDialog = true;
    },
    //表格排序
    sortChange(column) {
      this.filterForm.is_desc = !column.order
        ? -1
        : column.order === "ascending"
        ? 0
        : 1;
      this.filterForm.order_by = column.prop;
      this.getMyCredential(this.filterForm);
    },
    // 筛选回调
    ok(e) {
      this.filterForm = { ...this.filterForm, ...e, page: 1 };
      this.getMyCredential(this.filterForm);
    },
    // 分页查询
    changPage(e) {
      console.log(e);
      this.filterForm.page_size = e.page_size;
      const pageData = { ...this.filterForm, ...e };
      this.getMyCredential(pageData);
    },
  },
  created() {
    this.getMyCredential(this.filterForm);
  },
  components: {
    TransferListFilter,
    Pagination,
    TransferDetailDialog,
  },
};
</script>

<style lang="scss" scoped>
.body {
  min-height: calc(100vh - 270px);
}
.list-image {
  float: left;
  width: 110px;
  height: 60px;
}
</style>
